@import './fonts/Gilroy/Gilroy.css';
@import './fonts/Sk-Modernist/Sk-Modernist.css';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

input::-webkit-calendar-picker-indicator {
  background: url('./assets/images/Calendar.svg');
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

select:focus {
  box-shadow: none !important;
}

@layer utilities {
  @variants responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.fill-primary,
.fill-primary * {
  fill: #604cc5;
}

.fill-primary-tmo,
.fill-primary-tmo * {
  fill: #e7760d;
}

.phoneInput.react-tel-input .selected-flag .arrow {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid #555;
}

.selectDropdown {
  overflow-y: auto;
  box-shadow: 0 0 10px 10px #0000000a;
}
.selectDropdown::-webkit-scrollbar {
  width: 10px;
}
.selectDropdown::-webkit-scrollbar-track {
  background-color: rgb(231, 231, 231);
  border-radius: 10px;
}
.selectDropdown::-webkit-scrollbar-thumb {
  background-color: rgb(189, 189, 189);
  border-radius: 10px;
  border: 2px solid transparent;
}
.selectDropdown::-webkit-scrollbar-thumb:hover {
  background-color: #9c9c9c;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.no-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

input[type='checkbox'] {
  @apply !ring-transparent;
}
