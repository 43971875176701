.ProseMirror-focused {
  outline: none;
}

/* .vgip-button {
  display: none;
} */

html {
  max-height: 100vh;
  overflow-y: hidden;
}

input[type='text']:focus {
  box-shadow: none;
}

textarea:focus {
  box-shadow: none;
}

.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 200px;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderShimmer;
  -webkit-animation-timing-function: linear;
}

@keyframes placeholderShimmer {
  100% {
    background-position: -100% 0;
  }

  0% {
    background-position: 100% 0;
  }
}
