/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Sk-Modernist";
  src: url("./Sk-Modernist-Regular.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("./Sk-Modernist-Mono.otf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Sk-Modernist";
  src: url("./Sk-Modernist-Bold.otf") format("truetype");
  font-weight: bold;
}
